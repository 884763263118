@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer utilities {
  .thin-scroll::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  
  .hide-scroll { 
    -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
    scrollbar-width: none !important;   /* Firefox */
  }

  .hide-scroll::-webkit-scrollbar {
    display: none !important;  /* Safari and Chrome */
  }
}

body {
  font-family: 'Manrope', sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Manrope', sans-serif !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* scrollbar track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
/* scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #2F9D5B; 
  border-radius: 5px;
}

/* scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #298950;
}
